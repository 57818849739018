@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.App-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: calc(var(--vh, 1vh) * 100);
    font-family: 'Roboto', sans-serif;
    background-color: #303c8c;
    color: white;
}

.App-not-clickable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.App-half-page {
    height: 50%;
    width: 100%;
    position: relative;
}

.App-top-wrapper {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 100%;
    align-items: center;
}

.App-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: upper;
  font-size: calc(10px + 2vmin);
  width: 100%;
  text-align: center;
  position: relative;
}

.App-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

.App-button-card {
    background-color: #f8ac44;
    color: white;
    text-align: center;
    border: 0;
    transition-duration: 0.4s;
    overflow: hidden;
    cursor: pointer;
    flex-direction: row;
}

.App-button-card:active {
  transform: scale(0.95);
}

.App-button-card:disabled {
  opacity: 0.85;
  cursor: default;
}

.App-button-width {
    width: 300px;
    height: 50px;
    font-size: calc(10px + 2vmin);
    border-radius: 10px;
    margin: 5px;
}

.App-text-paragraph {
    font-weight: 200;
    font-size: 13px;
    line-height: 1.5;
    background-color: #303c8c;
}

.App-text-payment {
    font-weight: bolder;
    padding-bottom: 2px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 42px;
    border-bottom: 4px solid #f8ac44;
}

.App-text-orange {
    color: #f8ac44;
}

.App-loader {
  height: 15px;
  width: 65px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.App-circle{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0px;
}

.App-moving {
    animation: move 1000ms linear 0ms infinite;
    margin-right: 10px;
    margin-left: 10px;
}

.App-first-child{
    animation: grow 1000ms linear 0ms infinite;
}

.App-last-child{
    animation: grow 1000ms linear 0s infinite reverse;
}

@keyframes grow {
  from {transform: scale(0,0); opacity: 0;}
  to {transform: scale(1,1); opacity: 1;}
}

@keyframes move {
  from {transform: translateX(-25px)}
  to {transform: translateX(25px)}
}

.App-menu-button {
    display: inline-block;
    cursor: pointer;
    position: fixed;
    top: 20px;
}

.App-menu-wrapper {
    margin-top: 50px;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #f8ac44;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.change.bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
  transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.change.bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change.bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
  transform: rotate(45deg) translate(-8px, -8px) ;
}

.App-contacts {
    text-align: justify;
}

.App-icons {
    align-items: center;
    text-align: center;
    position: absolute;
    flex-direction: column;
    bottom: 0px;
}

.info {
  font-size: 24px;
  line-height: 1.5;
  margin-left: 5px;
  width: 20px;
  border-radius: 15px;
}

.App-receipt {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 5px;
    line-height: 0.6;
    text-align: center;
}
